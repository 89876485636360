const Terms = () => {
  return (
    <div>
      <div>
        <strong>1. Introduction </strong>
        <p className="mt-2">
          {" "}
          These <a href="#"> GmailBridge </a> Standard Terms and Conditions
          written on this webpage shall manage your use of this website. These
          Terms will be applied fully and affect to your use of this Website. By
          using this Website, you agreed to accept all terms and conditions
          written in here. You must not use this Website if you disagree with
          any of these Website Standard Terms and Conditions.
        </p>
        <p>
          Minors or people below 18 years old are not allowed to use this
          Website.{" "}
        </p>
      </div>

      <div className="mt-4">
        <strong>2. Intellectual Property Rights </strong>
        <p className="mt-2">
          {" "}
          Other than the content you own, under these Terms, GmailBridge and/or
          its licensors own all the intellectual property rights and materials
          contained in this Website. You are granted limited license only for
          purposes of viewing the material contained on this Website.
        </p>
      </div>

      <div className="mt-4">
        <strong>3. No warranties </strong>
        <p className="mt-2">
          This Website is provided 'as is', with all faults, and GmailBridge
          express no representations or warranties, of any kind related to this
          Website or the materials contained on this Website. Also, nothing
          contained on this Website shall be interpreted as advising you.
        </p>
      </div>

      <div className="mt-4">
        <strong>4. Variation of Terms </strong>
        <p className="mt-2">
          GmailBridge is permitted to revise these Terms at any time as it sees
          fit, and by using this Website you are expected to review these Terms
          on a regular basis.
        </p>
      </div>

      <div className="mt-4">
        <strong>5. Refund Policy </strong>
        <p className="mt-2">
          GmailBridge does not allow refund for the credits that you have
          purchased. We use a third-party vendor Gumroad for processing payments
          and can not refund the amount once credits are purchased.
        </p>
      </div>
    </div>
  );
};

export default Terms;
