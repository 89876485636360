import React, { useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBNavbarBrand,
} from "mdb-react-ui-kit";

import Terms from "./Terms";

import Privacy from "./Privacy";

const Footer = () => {
  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const toggleTerms = () => setShowTerms(!showTerms);
  const togglePrivacy = () => setShowPrivacy(!showPrivacy);
  return (
    <>
      <MDBModal open={showPrivacy} setOpen={setShowPrivacy} tabIndex="-1">
        <MDBModalDialog size="lg" scrollable>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Privacy Policy</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={togglePrivacy}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              {" "}
              <Privacy />
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={togglePrivacy}>
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <MDBModal open={showTerms} setOpen={setShowTerms} tabIndex="-1">
        <MDBModalDialog size="lg" scrollable>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Terms and Conditions</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleTerms}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              {" "}
              <Terms />
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={toggleTerms}>
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <div
        className="d-block d-sm-flex text-sm-start container mb-5 mt-5 text-center"
        id="faq"
      >
        <MDBNavbarBrand href="/#" className="fs-4">
          <img
            src={require("../assets/logo2.png")}
            alt=""
            style={{ width: "45px" }}
          ></img>
          GmailBridge
        </MDBNavbarBrand>
        <div className="mt-sm-0 me-0 ms-auto mt-3">
          <a className="ms-3" href="/#pricing">
            {" "}
            Pricing{" "}
          </a>
          <a className="ms-3" href={"javascript:void(0)"} onClick={toggleTerms}>
            {" "}
            Terms{" "}
          </a>
          <a
            className="ms-3"
            href={"javascript:void(0)"}
            onClick={togglePrivacy}
          >
            {" "}
            Privacy{" "}
          </a>
        </div>
      </div>
    </>
  );
};

export default Footer;
