import axios from "axios";
import { getAid, getCurrentUid } from "./firebase";
const LOCAL_SERVER = "http://localhost:5001";
const LOCAL_MACBOOK = "http://192.168.0.179:5001";
const PROD_SERVER = "https://themindmap.azurewebsites.net";

function getHost() {
  const hostname = window.location.hostname;
  if (hostname.includes("192.168")) {
    return LOCAL_MACBOOK;
  }
  return hostname.includes("localhost") ? LOCAL_SERVER : PROD_SERVER;
}

export async function signUpForEarlyAccess(email) {
  const url = getHost() + "/api/user/earlyaccess";
  const res = await axios.post(url, { email: email });
  return res.data;
}

export async function upgradeUser(licenseKey) {
  const uid = getCurrentUid();
  const url = getHost() + "/api/mail-bridge/user/" + uid + "/upgrade";
  const res = await axios.post(url, { licenseKey: licenseKey });
  return res.data;
}

export async function disconnectTelegramFun() {
  const uid = getCurrentUid();
  const url = getHost() + "/api/mail-bridge/user/" + uid + "/telegram";
  const res = await axios.post(url, { status: "DISABLED" });
  return res.data;
}

export async function getUserInfo() {
  const uid = getCurrentUid();
  const url = getHost() + "/api/mail-bridge/user/" + uid;
  const aid = await getAid();
  const config = {
    headers: {
      "x-token": aid,
    },
  };
  const res = await axios.get(url, config);
  return res.data;
}
