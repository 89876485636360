import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBProgress,
  MDBProgressBar,
  MDBCardFooter,
  MDBSwitch,
  MDBSpinner,
  MDBIcon,
  MDBModal,
  MDBModalDialog,
  MDBModalTitle,
  MDBModalBody,
  MDBModalContent,
  MDBModalHeader,
  MDBModalFooter,
  MDBInput,
} from "mdb-react-ui-kit";

import Navbar from "./Navbar";
import { authStates, withAuth } from "../utils/auth";
import { Navigate } from "react-router-dom";

import {
  getUserInfo,
  upgradeUser,
  disconnectTelegramFun,
} from "../utils/client";

const openGumroad = () => {
  window.open("https://thatman.gumroad.com/l/gmailbridge", "_blank");
};

const formattedDate = (timestamp) => {
  try {
    var date = new Date(timestamp);

    var dd = String(date.getDate()).padStart(2, "0"); // Day
    var mm = String(date.getMonth() + 1).padStart(2, "0"); // Month
    var yyyy = date.getFullYear(); // Year

    var formattedDate = dd + "/" + mm + "/" + yyyy;
    return formattedDate;
  } catch (err) {
    console.log(err);
    return "ERR";
  }
};

const Dashboard = ({ authState, user }) => {
  const [licenseKey, setLicenseKey] = useState("");
  const [telegramConnected, setTelegramConnected] = useState(false);
  const [remainingCredits, setRemainingCredits] = useState("NA");
  const [showTeleModal, setShowTeleModal] = useState(false);
  const [showRechargeModal, setShowRechargeModal] = useState(false);
  const [userInfo, setUserInfo] = useState(undefined);
  const [showLoader, setShowLoader] = useState(false);

  const fetchUser = async () => {
    try {
      setShowLoader(true);
      const userInfo = await getUserInfo();
      setTelegramConnected(userInfo.telegramConnected);
      setRemainingCredits(userInfo.remainingCredits);
      setUserInfo(userInfo);
      setShowLoader(false);
    } catch (err) {
      toast.error("Error while fetching data");
      setShowLoader(false);
    }
  };
  useEffect(() => {
    if (authState === authStates.LOGGED_IN) {
      fetchUser();
    }
  }, [authState]);

  const handleLicenseKeyChange = (event) => {
    setLicenseKey(event.target.value);
  };

  const teleSwitch = (e) => {
    if (telegramConnected) {
      setShowTeleModal(true);
    } else {
      window.open(
        "https://telegram.me/mailbridge_bot?start=" + userInfo?.mId,
        "_blank",
      );
    }
  };
  const upgradeNow = async (e) => {
    e.preventDefault();
    console.log("license key=" + licenseKey);
    setShowLoader(true);
    try {
      const updatedUser = await upgradeUser(licenseKey);
      toast.success("New credits added 🎉");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (err) {
      let msg = err?.response?.data?.message;
      if (!msg) msg = "Please try again in sometime";
      toast.error(msg);
    }
    setLicenseKey("");
    setShowLoader(false);
  };

  const disconnectTelegram = async () => {
    const updatedUser = await disconnectTelegramFun();
    setTelegramConnected(!telegramConnected);
    setShowTeleModal(false);
  };

  const toggleTeleModal = () => setShowTeleModal(!showTeleModal);
  const toggleRechargeModal = () => setShowRechargeModal(!showRechargeModal);
  const getUserName = () => {
    if (user && user.displayName) {
      return user.displayName;
    } else if (user && user.email) {
      return user.email;
    }
    return "User";
  };

  const userName = getUserName();

  if (authState === authStates.INITIAL_VALUE) {
    return (
      <div className={"text-center"}>
        <MDBSpinner color="primary">
          <span className="visually-hidden">Loading...</span>
        </MDBSpinner>
      </div>
    );
  }

  if (authState === authStates.LOGGED_OUT) {
    return <Navigate to="/login"></Navigate>;
  }

  return (
    <div style={{ backgroundColor: "aliceblue", height: "100vh" }}>
      <MDBModal open={showTeleModal} setOpen={setShowTeleModal} tabIndex="-1">
        <MDBModalDialog scrollable>
          <MDBModalContent>
            <MDBModalHeader style={{ background: "#51829B" }}>
              <MDBModalTitle className="fw-bold" style={{ color: "#ffffff" }}>
                <MDBIcon fas icon="exclamation-circle" /> Are you sure?
              </MDBModalTitle>
              <MDBBtn className="btn-close" onClick={toggleTeleModal}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody className="text-center" style={{ fontSize: "18px" }}>
              <MDBIcon fas icon="exclamation-triangle" />
              &nbsp;You will stop receiving telegram notifications if you
              disconnected.
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="secondary"
                className="fs-6 fw-bold mt-1 shadow"
                onClick={toggleTeleModal}
              >
                Close
              </MDBBtn>
              <MDBBtn
                className="fs-6 fw-bold mt-1 shadow"
                onClick={disconnectTelegram}
              >
                Confirm
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <MDBModal
        open={showRechargeModal}
        setOpen={setShowRechargeModal}
        tabIndex="-1"
      >
        <MDBModalDialog scrollable>
          <MDBModalContent>
            <MDBModalHeader style={{ background: "#51829B" }}>
              <MDBModalTitle className="fw-bold" style={{ color: "#ffffff" }}>
                <MDBIcon fas icon="plus-square" /> Add more credits
              </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                onClick={toggleRechargeModal}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody className="text-center" style={{ fontSize: "18px" }}>
              <MDBIcon fas icon="shopping-cart" />
              &nbsp;Please enter the license key purchased on{" "}
              <a
                href="https://thatman.gumroad.com/l/gmailbridge"
                target="_blank"
                rel="noreferrer"
              >
                Gumroad{" "}
              </a>
              .
              <form onSubmit={upgradeNow}>
                <div className="d-flex justify-content-center mb-2">
                  <MDBInput
                    label="Enter your license key"
                    id="waitlistBox"
                    onChange={handleLicenseKeyChange}
                    required
                    type="text"
                    name={licenseKey}
                    value={licenseKey}
                  />
                  <MDBBtn
                    outline
                    className="btn mx-2"
                    color="dark"
                    type="submit"
                  >
                    Verify
                  </MDBBtn>
                </div>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="secondary"
                className="fs-6 fw-bold mt-1 shadow"
                onClick={toggleRechargeModal}
              >
                Close
              </MDBBtn>
              {/* <MDBBtn className="fs-6 fw-bold mt-1 shadow" onClick={upgradeNow}>
                Confirm
              </MDBBtn> */}
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      {showLoader && (
        <div
          className={"text-center"}
          style={{
            position: "fixed",
            left: "0%",
            top: "30%",
            width: "100%",
            height: "100%",
            zIndex: "9999",
          }}
        >
          <MDBSpinner color="info">
            <span className="visually-hidden">Loading...</span>
          </MDBSpinner>
        </div>
      )}
      <Navbar isDashboard="true" />
      <MDBContainer className="mt-4">
        <div className="fs-3 text-center">
          {" "}
          Welcome <span style={{ fontWeight: "bold" }}>{userName} 👋</span>
        </div>
        <MDBRow className="mt-3">
          <MDBCol size="md">
            <MDBCard
              className="h-100 shadow"
              style={{ backgroundColor: "#F6F5F5" }}
            >
              <MDBCardBody>
                <MDBCardTitle className="fs-4">
                  Your remaining credits
                </MDBCardTitle>
                <MDBCardText className="d-flex justify-content-center align-items-center mt-4">
                  <span
                    className={
                      remainingCredits > 15
                        ? "numberCircle clrGreen"
                        : "numberCircle clrRed"
                    }
                  >
                    <span>{remainingCredits}</span>
                  </span>
                </MDBCardText>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <MDBBtn
                    onClick={toggleRechargeModal}
                    className="fs-6 fw-bold mt-1 shadow"
                    outline
                    size="sm"
                    // style={{ color: "#211C6A" }}
                  >
                    Add Credits
                  </MDBBtn>

                  <MDBBtn
                    onClick={openGumroad}
                    className="fs-6 fw-bold mt-1 shadow"
                    outline
                    size="sm"
                    color="info"
                    // style={{ color: "#211C6A" }}
                  >
                    Buy More
                  </MDBBtn>
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol size="md" className="mt-md-0 mt-5">
            <MDBCard
              className="h-100 shadow"
              style={{ backgroundColor: "#F6F5F5" }}
            >
              <MDBCardBody>
                <MDBCardTitle className="fs-4">Your stats</MDBCardTitle>
                <MDBCardText>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "3rem",
                    }}
                  >
                    <div>
                      <MDBIcon fas icon="angle-double-right" /> &nbsp;
                      <strong>Total notifications sent </strong>
                    </div>
                    <div>
                      {" "}
                      {userInfo && userInfo["totalSent"] !== undefined
                        ? userInfo.totalSent
                        : "NA"}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "1rem",
                    }}
                  >
                    <div>
                      <MDBIcon fas icon="clock" /> &nbsp;
                      <strong>Last sent on</strong>
                    </div>
                    <div>
                      {" "}
                      {userInfo && userInfo["lastSentOn"] !== undefined
                        ? formattedDate(userInfo.lastSentOn)
                        : "NA"}
                    </div>
                  </div>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol size="md" className="mt-md-0 mt-5">
            <MDBCard
              className="h-100 shadow"
              style={{ backgroundColor: "#F6F5F5" }}
            >
              <MDBCardBody>
                <MDBCardTitle className="fs-4">Your accounts</MDBCardTitle>
                <MDBCardText>
                  <MDBRow style={{ marginTop: "3rem" }}>
                    <MDBCol>
                      <MDBIcon fab icon="telegram-plane" /> &nbsp;
                      <strong>Telegram </strong>
                    </MDBCol>
                    <MDBCol>
                      {" "}
                      <MDBSwitch
                        id="flexSwitchCheckDefault"
                        label={
                          telegramConnected == false
                            ? "Not Connected"
                            : "Connected"
                        }
                        checked={telegramConnected}
                        onChange={teleSwitch}
                      />
                    </MDBCol>
                  </MDBRow>
                </MDBCardText>
              </MDBCardBody>
              <MDBCardFooter className="text-muted">
                <p
                  className="mt-4"
                  style={{ color: "darkgrey", fontSize: "small" }}
                >
                  We are working on bringing slack and discord integrations as
                  well soon
                </p>
              </MDBCardFooter>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow className="mt-5">
          <MDBCol size="lg">
            <MDBCard className="shadow" style={{ backgroundColor: "#F6F5F5" }}>
              <MDBCardBody>
                <MDBCardTitle className="fs-4">
                  How to use this app
                </MDBCardTitle>
                <MDBCardText style={{ marginTop: "2rem" }}>
                  <p>
                    <strong> Step 1: </strong> Use{" "}
                    <a
                      href="https://support.google.com/mail/answer/10957?hl=en#zippy=%2Cturn-automatic-forwarding-on-or-off"
                      target="_blank"
                      rel="noreferrer"
                    >
                      this guide{" "}
                    </a>{" "}
                    and whitelist{" "}
                    <span className="fw-bold text-warning">
                      notify@gmailbridge.com
                    </span>{" "}
                    in your gmail account for email forwarding. It will take
                    sometime for the email to be whitelisted.
                  </p>
                  <p>
                    <strong> Step 2: </strong> For the emails for which you need
                    a telegram notification, create a simple filter to
                    automatically forward them to{" "}
                    <span className="fw-bold text-warning">
                      notify@gmailbridge.com
                    </span>{" "}
                  </p>
                  <p>
                    <strong> Step 3: </strong> Connect your Telegram account
                    using above switch (under 'Your accounts' section) to start
                    receiving instant notifications. Click{" "}
                    <span className="fw-bold"> "Open in Web" </span> button in
                    the new tab under telegram to join the bot. <br />
                    <span
                      className="fw-lighter"
                      style={{ fontSize: "x-small" }}
                    >
                      {" "}
                      If you are not seeing 'Open in Web' button on telegram
                      page, you have probably not logged in to{" "}
                      <a
                        href="https://web.telegram.org/a/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        telegram web.
                      </a>
                    </span>
                  </p>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>{" "}
        </MDBRow>
      </MDBContainer>
      <Toaster />
    </div>
  );
};

export default withAuth(Dashboard);
