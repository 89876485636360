const Privacy = () => {
  return (
    <div>
      <p>
        At GmailBridge, one of our main priorities is the privacy of our
        visitors. This Privacy Policy document contains types of information
        that is collected and recorded by GmailBridge and how we use it.{" "}
      </p>
      <p>
        {" "}
        If you have additional questions or require more information about our
        Privacy Policy, do not hesitate to contact us through email at{" "}
        <span className="fw-bold text-warning">
          thatman.dev121@gmail.com
        </span>{" "}
      </p>
      <div>
        <strong>Data Collection </strong>
        <p className="mt-2">
          In order to use the GmailBridge application, the following personal
          data will be collected:
          <ul>
            <li>
              {" "}
              Email address - used to identify and contact users if/when needed.{" "}
            </li>
            <li>
              {" "}
              Billing Information (stored by{" "}
              <a href="https://gumroad.com/" target="_blank" rel="noreferrer">
                Gumroad
              </a>
              , not accessible to GmailBridge directly ) - Used to verify user
              credits and enable the features of the application.
            </li>
          </ul>
        </p>
      </div>

      <div className="mt-4">
        <strong>Online Privacy Policy Only </strong>
        <p className="mt-2">
          This privacy policy applies only to our online activities and is valid
          for visitors to our website with regards to the information that they
          shared and/or collect in GmailBridge. This policy is not applicable to
          any information collected offline or via channels other than this
          website.
        </p>
      </div>

      <div className="mt-4">
        <strong>Consent </strong>
        <p className="mt-2">
          By using our website, you hereby consent to our Privacy Policy and
          agree to its Terms and Conditions.
        </p>
      </div>
    </div>
  );
};

export default Privacy;
