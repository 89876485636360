import { useState, useEffect } from "react";
import { MDBInput, MDBBtn, MDBIcon } from "mdb-react-ui-kit";
import { googleSignIn, signOut } from "../utils/firebase";
import { authStates, withAuth } from "../utils/auth";
import { Navigate } from "react-router-dom";

const Login = ({ authState, user }) => {
  if (authState === authStates.LOGGED_IN) {
    return <Navigate to="/dashboard"></Navigate>;
  }
  return (
    <div
      className="text-center"
      style={{
        display: "flex",
        alignItems: "center",
        paddingTop: "40px",
        paddingBottom: "40px",
        backgroundColor: "#f5f5f5",
      }}
    >
      <div
        style={{
          width: "100%",
          maxWidth: "330px",
          padding: "15px",
          margin: "auto",
        }}
      >
        <h1 className="h3 fw-normal mb-3"> Please sign in</h1>
        <p> Please login to view this resource </p>
        <div className="container-xs mt-2">
          <MDBBtn
            className="fs-6 fw-bold mt-1 text-center"
            size="md"
            color="info"
            style={{ fontFamily: '"Poppins", sans-serif !important' }}
            onClick={googleSignIn}
          >
            <MDBIcon fab icon="google" /> {"\t\t"}
            &nbsp; Login with Google
          </MDBBtn>
        </div>
      </div>
    </div>
  );
};

export default withAuth(Login);
