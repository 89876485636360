import { useState, useEffect } from "react";

import React from "react";
import toast, { Toaster } from "react-hot-toast";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBCollapse,
  MDBIcon,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBModalTitle,
  MDBInput,
  MDBSpinner,
} from "mdb-react-ui-kit";
import { signUpForEarlyAccess } from "../utils/client";
import { googleSignIn, signOut } from "../utils/firebase";
import { authStates, withAuth } from "../utils/auth";
import { useNavigate } from "react-router-dom";

const Navbar3 = ({ authState, user, isDashboard }) => {
  const [openNav, setOpenNav] = useState(false);
  // const [showLogin, setShowLogin] = useState(false);
  const [showWaitlist, setShowWaitlist] = useState(false);
  const [email, setEmail] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(
    authState === authStates.LOGGED_IN,
  );
  const navigateNow = useNavigate();
  // useEffect(() => {
  //   const buyBtn = document.getElementById("buy-now-btn");
  //   buyBtn?.addEventListener("click", function (e) {
  //     toggleWaitlist();
  //   });
  // }, []);

  useEffect(() => {
    if (authState === authStates.LOGGED_IN) {
      // toast.success("Hello " + getUserName());
      setIsLoggedIn(true);
      setShowWaitlist(false);
    }
  }, [authState]);

  const getUserName = () => {
    if (user && user.displayName) {
      return user.displayName;
    } else if (user && user.email) {
      return user.email;
    }
    return "User";
  };

  // const toggleLogin = () => setShowLogin(!showLogin);
  const toggleWaitlist = () => setShowWaitlist(!showWaitlist);
  const logout = () => {
    signOut()
      .then(() => {
        console.log("Signed Out");
        setIsLoggedIn(false);
      })
      .catch((e) => {
        console.log("Error signing out", e);
      });
  };

  const openDashboard = () => {
    return navigateNow("/dashboard");
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const joinWaitlist = async (e) => {
    e.preventDefault();
    console.log("join waitlist for " + email);
    try {
      const res = await signUpForEarlyAccess(email);
      toast.success("We will notify you 😀");
      toggleWaitlist();
    } catch (err) {
      toast.error("Please try again in sometime");
    }
    setEmail("");
  };

  if (authState === authStates.INITIAL_VALUE) {
    return (
      <div className={"text-center"}>
        <MDBSpinner color="primary">
          <span className="visually-hidden">Loading...</span>
        </MDBSpinner>
      </div>
    );
  }

  return (
    <>
      {/* <MDBModal open={showWaitlist} setOpen={setShowWaitlist} tabIndex="-1">
        <MDBModalDialog scrollable>
          <MDBModalContent>
            <MDBModalHeader style={{ background: "#51829B" }}>
              <MDBModalTitle className="fw-bold" style={{ color: "#ffffff" }}>
                {" "}
                Join the Waitlist
              </MDBModalTitle>
              <MDBBtn className="btn-close" onClick={toggleWaitlist}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody className="text-center" style={{ fontSize: "18px" }}>
              &nbsp;GmailBridge is on it's way.{" "}
              <MDBIcon fas icon="shipping-fast" size="lg" />
              <br /> Sign up to be the first one to know when we launch.
              <div className="container-xs mt-2">
                <form onSubmit={joinWaitlist}>
                  <div className="d-flex justify-content-center mb-2">
                    <MDBInput
                      label="Email"
                      id="waitlistBox"
                      onChange={handleEmailChange}
                      required
                      type="email"
                      name={email}
                      value={email}
                    />
                    <MDBBtn
                      outline
                      className="btn mx-2"
                      color="dark"
                      type="submit"
                    >
                      Join
                    </MDBBtn>
                  </div>
                </form>
              </div>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal> */}

      {/* Login Popup */}
      <MDBModal open={showWaitlist} setOpen={setShowWaitlist} tabIndex="-1">
        <MDBModalDialog scrollable>
          <MDBModalContent>
            <MDBModalHeader style={{ background: "#51829B" }}>
              <MDBModalTitle className="fw-bold" style={{ color: "#ffffff" }}>
                {" "}
                Let's get in <MDBIcon fas icon="arrow-right" />
              </MDBModalTitle>
              <MDBBtn className="btn-close" onClick={toggleWaitlist}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody className="text-center" style={{ fontSize: "18px" }}>
              <MDBBtn
                className="fs-6 fw-bold mt-1 text-center"
                size="md"
                color="info"
                style={{ fontFamily: '"Poppins", sans-serif !important' }}
                onClick={googleSignIn}
              >
                <MDBIcon fab icon="google" /> {"\t\t"}
                &nbsp; Login with Google
              </MDBBtn>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBNavbar expand="lg" light bgColor="light">
        <MDBContainer fluid>
          <MDBNavbarBrand
            href={isDashboard == "true" ? "/home" : "#"}
            className="ms-md-5 fs-4"
          >
            <img
              src={require("../assets/logo2.png")}
              alt=""
              style={{ width: "45px" }}
            ></img>
            GmailBridge
          </MDBNavbarBrand>
          <MDBNavbarToggler
            type="button"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setOpenNav(!openNav)}
          >
            <MDBIcon icon="bars" fas />
          </MDBNavbarToggler>
          <MDBCollapse navbar open={openNav}>
            <MDBNavbarNav className="justify-content-end me-md-5 ms-auto">
              {isDashboard !== "true" && (
                <MDBNavbarItem>
                  <MDBNavbarLink
                    active
                    aria-current="page"
                    href="#how-it-works"
                  >
                    How it Works
                  </MDBNavbarLink>
                </MDBNavbarItem>
              )}

              {isDashboard !== "true" && (
                <MDBNavbarItem>
                  <MDBNavbarLink href="#faq">FAQ</MDBNavbarLink>
                </MDBNavbarItem>
              )}

              {isDashboard !== "true" && (
                <MDBNavbarItem>
                  <MDBNavbarLink href="#pricing">Pricing</MDBNavbarLink>
                </MDBNavbarItem>
              )}
              {isLoggedIn && isDashboard === "true" && (
                <MDBBtn
                  className="fs-6 fw-bold mx-1"
                  size="sm"
                  color="secondary"
                  style={{ fontFamily: '"Poppins", sans-serif !important' }}
                  onClick={logout}
                >
                  Logout
                </MDBBtn>
              )}
              {isLoggedIn && isDashboard !== "true" && (
                <MDBBtn
                  className="fs-6 fw-bold mx-1"
                  size="sm"
                  color="secondary"
                  style={{ fontFamily: '"Poppins", sans-serif !important' }}
                  onClick={openDashboard}
                >
                  Dashboard
                </MDBBtn>
              )}
              {!isLoggedIn && (
                <MDBBtn
                  className="fs-6 fw-bold mx-1"
                  size="sm"
                  color="info"
                  style={{ fontFamily: '"Poppins", sans-serif !important' }}
                  onClick={toggleWaitlist}
                >
                  Login
                </MDBBtn>
              )}
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
      <Toaster />
    </>
  );
};

export default withAuth(Navbar3);
