const FAQ = () => {
  return (
    <div className="mt-sm-5 mb-5 mt-2" id="faq">
      <h3
        className="text-center"
        style={{ fontWeight: "600", fontSize: "calc(1.005rem + 2vw)" }}
      >
        FAQ
      </h3>{" "}
      <div className="row mt-5">
        <div className="col-lg-6 col-12 text-start">
          <div>
            <h4 className="fw-bold">Is there a free trial? </h4>
            <p style={{ fontSize: "18px" }}>
              Yes! You can try GmailBridge for free and see the magic for
              yourself. You get 25 free credits in your account when you login
              for the first time.
            </p>
          </div>
        </div>

        <div className="col-lg-6 col-12 text-start">
          <div>
            <h4 className="fw-bold">Do you support only telegram?</h4>
            <p style={{ fontSize: "18px" }}>
              {" "}
              Currently, we support notifications only on telegram but we are
              planning to integrate with slack as well as discord soon.
            </p>
          </div>
        </div>

        <div className="col-lg-6 col-12 text-start">
          <div>
            <h4 className="fw-bold">Can I buy credits multiple times? </h4>
            <p style={{ fontSize: "18px" }}>
              {" "}
              Yes! You can buy as many credits as you want. Infact, if you need
              credits in bulk, we can provide custom pricing as well. You can
              write to us at:{" "}
              <span className="fw-bold text-secondary">
                {" "}
                thatman.dev121@gmail.com{" "}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
