import firebase from "firebase/compat/app";

import "firebase/compat/auth";

// if (!process.env.REACT_APP_FIREBASE_CONFIG) {
//   console.error("REACT_APP_FIREBASE_CONFIG must be defined");
//   console.log("ENV: ", process.env);
// }

const FIREBASE_CONF = {
  apiKey: "AIzaSyDLQpzhw3riCsTPiTX1ojV_a6HUa4cPRnE",
  authDomain: "mailbridge-297aa.firebaseapp.com",
  projectId: "mailbridge-297aa",
  storageBucket: "mailbridge-297aa.appspot.com",
  messagingSenderId: "183193302504",
  appId: "1:183193302504:web:e59bcb950a863b7910a05d",
  measurementId: "G-0W9B5Y0PY2",
};
// const firebaseConfig = JSON.parse(FIREBASE_CONF);

export function initialize() {
  // Initialize Firebase
  if (!firebase.apps.length) {
    firebase.initializeApp(FIREBASE_CONF);
  }
}

export async function googleSignIn() {
  const res = await firebase
    .auth()
    .signInWithPopup(new firebase.auth.GoogleAuthProvider());
}

export function attachAuthListener(handler) {
  return firebase.auth().onAuthStateChanged((user) => {
    handler(user);
  });
}

export async function createNewUser(email, password) {
  await firebase.auth().createUserWithEmailAndPassword(email, password);
}

export async function signIn(email, password) {
  await firebase.auth().signInWithEmailAndPassword(email, password);
}

export async function signOut() {
  await firebase.auth().signOut();
}

export async function getAid() {
  return await firebase.auth()?.currentUser?.getIdToken();
}

export function getCurrentUid() {
  return firebase.auth()?.currentUser?.uid;
}

// https://github.com/asmitab/react-firebase-login
