const HowItWorks = () => {
  return (
    <div>
      <div className="mt-sm-5 mb-5 mt-2" id="how-it-works">
        <h3
          className="text-center"
          style={{ fontWeight: "600", fontSize: "calc(1.005rem + 2vw)" }}
        >
          How it works ?
        </h3>

        <div
          className="d-flex mt-2"
          style={{ justifyContent: "center", margin: "auto" }}
        >
          <div
            style={{
              padding: "15px 30px",
              borderRadius: "50px",
              border: "1px solid #BDCDD6",
              background: "#BDCDD6",
              fontSize: "25px",
              fontWeight: 600,
              alignSelf: "center",
            }}
          >
            1
          </div>
          <div
            className="ms-2"
            style={{
              padding: "20px",
              borderRadius: "12px",
              border: "1px solid #BDCDD6",
              fontSize: "18px",
              maxWidth: "100%",
              width: "770px",
            }}
          >
            <b>Login via Google</b> - Click the Login button on top and perform
            login via your google account. That way we will know your email id.
          </div>
        </div>

        <div
          className="d-flex mt-3"
          style={{ justifyContent: "center", margin: "auto" }}
        >
          <div
            style={{
              padding: "15px 30px",
              borderRadius: "50px",
              border: "1px solid #BDCDD6",
              background: "#BDCDD6",
              fontSize: "25px",
              fontWeight: 600,
              alignSelf: "center",
            }}
          >
            2
          </div>
          <div
            className="ms-2"
            style={{
              padding: "20px",
              borderRadius: "12px",
              border: "1px solid #BDCDD6",
              fontSize: "18px",
              maxWidth: "100%",
              width: "770px",
            }}
          >
            <b>Enable email forwarding</b> - Use{" "}
            <a
              href="https://support.google.com/mail/answer/10957?hl=en#zippy=%2Cturn-automatic-forwarding-on-or-off"
              target="_blank"
              rel="noreferrer"
            >
              this guide
            </a>
            {"\t"}and whitelist{"\t"}
            <span className="fw-bold text-warning">
              notify@gmailbridge.com
            </span>{" "}
            {"\t"}in your gmail account for email forwarding.
          </div>
        </div>
        <div
          className="d-flex mt-3"
          style={{ justifyContent: "center", margin: "auto" }}
        >
          <div
            style={{
              padding: "15px 30px",
              borderRadius: "50px",
              border: "1px solid #BDCDD6",
              background: "#BDCDD6",
              fontSize: "25px",
              fontWeight: 600,
              alignSelf: "center",
            }}
          >
            3
          </div>
          <div
            className="ms-2"
            style={{
              padding: "20px",
              borderRadius: "12px",
              border: "1px solid #BDCDD6",
              fontSize: "18px",
              maxWidth: "100%",
              width: "770px",
            }}
          >
            <b>Create a filter</b> - For the emails for which you need a
            telegram notification, create a simple filter to automatically forward them to{" "}
            <span className="fw-bold text-warning">notify@gmailbridge.com</span>
          </div>
        </div>

        <div
          className="d-flex mt-3"
          style={{ justifyContent: "center", margin: "auto" }}
        >
          <div
            style={{
              padding: "15px 30px",
              borderRadius: "50px",
              border: "1px solid #BDCDD6",
              background: "#BDCDD6",
              fontSize: "25px",
              fontWeight: 600,
              alignSelf: "center",
            }}
          >
            4
          </div>
          <div
            className="ms-2"
            style={{
              padding: "20px",
              borderRadius: "12px",
              border: "1px solid #BDCDD6",
              fontSize: "18px",
              maxWidth: "100%",
              width: "770px",
            }}
          >
            <b>Link your Telegram account</b> - Go to your dashboard and link
            your telegram account. Your dashboard will be visible as soon as you
            login.
          </div>
        </div>

        <div
          className="d-flex mt-3"
          style={{ justifyContent: "center", margin: "auto" }}
        >
          <div
            style={{
              padding: "15px 30px",
              borderRadius: "50px",
              border: "1px solid #BDCDD6",
              background: "#BDCDD6",
              fontSize: "25px",
              fontWeight: 600,
              alignSelf: "center",
            }}
          >
            5
          </div>
          <div
            className="ms-2"
            style={{
              padding: "20px",
              borderRadius: "12px",
              border: "1px solid #BDCDD6",
              fontSize: "18px",
              maxWidth: "100%",
              width: "770px",
            }}
          >
            <b>Thas's it</b> - Now we wait for your emails. As soon as we
            receive any forwarded email from your gmail filter, we send you
            instant notifications on your telegram.
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
