import React, { useState } from "react";
import { MDBIcon, MDBBtn } from "mdb-react-ui-kit";
import HowItWorks from "./HowItWorks";
import FAQ from "./FAQ";
import Footer from "./Footer";

const Hero = () => {
  return (
    <>
      <div className="mx-lg-5 mx-3">
        <div
          className="row mt-sm-3 pt-sm-3 mb-5 mt-2 pb-5 pt-2"
          style={{ alignItems: "center" }}
        >
          <div
            className="col-12 col-lg-7 text-lg-start text-center"
            style={{ textAlign: "left" }}
          >
            <h1 style={{ fontSize: "calc(1.675rem + 2vw)", fontWeight: 700 }}>
              <MDBIcon far icon="envelope" /> Get instant {"\t"}
              <span
                className="mt-xl-0 mt-1"
                style={{
                  color: "#F6995C",
                }}
              >
                notifications on telegram
              </span>
              {"\t"} for your filtered emails in
              <span
                className="mt-xl-0 mt-1"
                style={{
                  color: "#51829B",
                }}
              >
                {"\t"}Gmail
              </span>
            </h1>
          </div>
          <div className="col-12 col-lg-5 mt-lg-0 mt-5">
            <img
              src={require("../assets/hero.png")}
              alt=""
              style={{ width: "700px", maxWidth: "100%" }}
            ></img>
          </div>
        </div>

        <div className="mt-sm-5 mb-5 mt-2">
          <h3
            className="text-center"
            style={{ fontWeight: "600", fontSize: "calc(1.005rem + 2vw)" }}
          >
            {" "}
            Why use GmailBridge ?
          </h3>
          <div className="row row-cols-1 row-cols-md-2 mt-5">
            <div
              className="col"
              style={{
                background: "#fdb2b2",
                padding: "20px",
                borderRadius: "10px",
              }}
            >
              <p className="fw-bolder fs-5">
                {" "}
                Well, of-course you can do it with {"\t"}
                <span
                  style={{
                    color: "#EEE9DA",
                    background: "#2b4350",
                    padding: "5px",
                  }}
                >
                  Zapier
                </span>
                {"\t"} as well But...
              </p>
              <p className="fs-5">
                {" "}
                You will need to give it permission to read all your emails.
              </p>
              <p className="fs-5">
                It does not support setting specific permissions for individual
                folders or labels within your email. This means you cannot
                restrict access to only certain parts of your email account.
              </p>
              <p
                className="fw-bolder fs-6"
                style={{
                  flex: "0 0 auto",
                  width: "100%",
                }}
              >
                You share:{"\t"}
                <span
                  style={{
                    color: "#EEE9DA",
                    background: "#2b4350",
                    padding: "5px",
                  }}
                >
                  full read-access
                </span>
                {"\t"}to your gmail account.
              </p>
            </div>
            <div className="col">
              <div className="col mt-sm-0 mt-2"></div>
              <div
                className="d-flex"
                style={{
                  background: "#a7dcb7",
                  padding: "20px",
                  borderRadius: "10px",
                  flexWrap: "wrap",
                  height: "100%",
                }}
              >
                <p
                  className="fw-bolder fs-5"
                  style={{ flex: "0 0 auto", width: "100%" }}
                >
                  We {"\t"}
                  <span
                    style={{
                      color: "#EEE9DA",
                      background: "#2b4350",
                      padding: "5px",
                    }}
                  >
                    don't read
                  </span>
                  {"\t"}all your emails...
                </p>
                <p
                  className="fs-5"
                  style={{ marginTop: "auto", flex: "0 0 auto", width: "100%" }}
                >
                  Because we don't need to.
                </p>
                <p
                  className="fs-5"
                  style={{ marginTop: "auto", flex: "0 0 auto", width: "100%" }}
                >
                  With <span className="fw-bold">GmailBridge</span>, You just
                  need to set up a filter rule in Gmail that forwards specific
                  emails to us. These are the emails you want to get instant
                  notifications for.
                </p>
                <p
                  className="fw-bolder fs-6"
                  style={{ flex: "0 0 auto", width: "100%" }}
                >
                  You share:{"\t"}
                  <span
                    style={{
                      color: "#EEE9DA",
                      background: "#2b4350",
                      padding: "5px",
                    }}
                  >
                    only specific emails
                  </span>
                  {"\t"}for which you need notifications.
                </p>
              </div>
            </div>
          </div>
        </div>
        <HowItWorks />

        <div className="mt-sm-5 mb-5 mt-4" id="pricing">
          <h3
            className="text-center"
            style={{ fontWeight: "600", fontSize: "calc(1.005rem + 2vw)" }}
          >
            Simple, transparent pricing
          </h3>
          <div
            className="text-center"
            style={{ fontSize: "20px", fontWeight: 400 }}
          >
            <p className="mt-4">
              We use a simple credit system to charge you for the notifications.
            </p>
            <p>
              {" "}
              For every notification sent, we deduct 1 credit from your account.{" "}
            </p>
            <p>
              {" "}
              You get {"\t"}
              <span
                style={{
                  color: "#EEE9DA",
                  background: "#2b4350",
                  padding: "5px",
                }}
              >
                free 25 credits
              </span>
              {"\t"} in your account when you login for the first time.
            </p>
          </div>

          <div
            className="d-flex align-items-center justify-content-center"
            style={{ marginTop: "2rem" }}
          >
            <div className="row">
              <div className="col">
                <div className="card rounded-3 mb-4 shadow">
                  <div className="card-header">
                    <h4 className="fw-bolder" style={{ color: "#51829B" }}>
                      Need more credits ?
                    </h4>
                  </div>
                  <div className="card-body">
                    <div>
                      <span
                        className="fw-bold"
                        style={{
                          color: "#7F9F80",
                          fontWeight: 800,
                          fontSize: "24px",
                        }}
                      >
                        $12{" "}
                        <small className="text-muted fw-light">
                          {" "}
                          / one time payment
                        </small>
                      </span>
                    </div>
                    <div>
                      {" "}
                      <ul
                        style={{
                          listStyle: "none",
                          paddingLeft: 0,
                          marginTop: "1rem",
                        }}
                      >
                        {" "}
                        <li>
                          {" "}
                          <MDBIcon
                            fas
                            icon="check-circle"
                            style={{ color: "#51829B" }}
                          />
                          {"\t"}
                          No expiry
                        </li>{" "}
                        <li>
                          {" "}
                          <MDBIcon
                            fas
                            icon="check-circle"
                            style={{ color: "#51829B" }}
                          />
                          {"\t"}
                          100 credits
                        </li>{" "}
                        <li>
                          {" "}
                          <MDBIcon
                            fas
                            icon="check-circle"
                            style={{ color: "#51829B" }}
                          />
                          {"\t"}
                          24/7 Support
                        </li>{" "}
                        <li>
                          {" "}
                          <MDBIcon
                            fas
                            icon="check-circle"
                            style={{ color: "#51829B" }}
                          />
                          {"\t"}
                          Monthly Reports
                        </li>{" "}
                      </ul>
                    </div>
                    <MDBBtn
                      id="buy-now-btn"
                      className="fs-6 fw-bold mt-1"
                      size="md"
                      color="info"
                      onClick={() =>
                        window.open(
                          "https://thatman.gumroad.com/l/gmailbridge",
                          "_blank",
                        )
                      }
                      style={{ fontFamily: '"Poppins", sans-serif !important' }}
                    >
                      Buy Now on Gumroad
                    </MDBBtn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <FAQ />
        <Footer />
      </div>
    </>
  );
};

export default Hero;
